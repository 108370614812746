import {
	PresentionChart,
	LinkCircle,
	TrendUp,
	Setting3,
	MoneyRecive,
	WalletAdd,
	Rank,
	Profile2User,
	Clock,
} from 'iconsax-react';

const navigation = [
	{
		header: 'DASHBOARD',
		roles: ['affiliated'],
	},
	{
		id: 'dashboards-analytics',
		title: 'sidemenu.analises',
		icon: <PresentionChart size={18} />,
		icon2: <PresentionChart variant="Bold" size={18} />,
		navLink: '/analises',
		roles: ['affiliated'],
	},
	{
		id: 'contestations',
		title: 'sidemenu.solicitacoes',
		icon: <Clock size={18} />,
		icon2: <Clock variant="Bold" size={18} />,
		navLink: '/contestations',
		roles: ['affiliated'],
	},
	{
		id: 'dashboards-home',
		title: 'sidemenu.afiliados',
		icon: <Profile2User size={18} />,
		icon2: <Profile2User variant="Bold" size={18} />,
		navLink: '/home',
		roles: ['admin'],
	},
	{
		header: 'sidemenu.meuslinks',
		roles: ['affiliated'],
	},
	{
		id: 'apps-calendar',
		title: 'sidemenu.links',
		icon: <LinkCircle size={18} />,
		icon2: <LinkCircle variant="Bold" size={18} />,
		navLink: '/links-personalizados',
		roles: ['affiliated'],
	},
	{
		header: 'sidemenu.estatisticas',
		roles: ['affiliated'],
	},
	{
		id: 'dashboards-ecommerce',
		title: 'sidemenu.desempenho',
		icon: <TrendUp size={18} />,
		icon2: <TrendUp variant="Bold" size={18} />,
		navLink: '/desempenho',
		roles: ['affiliated'],
	},
	{
		header: 'sidemenu.subAfiliados',
		roles: ['affiliated'],
	},
	{
		id: 'user-interface-buttons',
		title: 'sidemenu.gerenciamento',
		icon: <Setting3 size={18} />,
		icon2: <Setting3 variant="Bold" size={18} />,
		navLink: '/gerenciamento',
		roles: ['affiliated'],
	},
	{
		header: 'sidemenu.carteira',
		roles: ['affiliated'],
	},
	{
		id: 'charts',
		title: 'sidemenu.gestao',
		icon: <MoneyRecive size={18} />,
		icon2: <MoneyRecive variant="Bold" size={18} />,
		navLink: '/carteira',
		roles: ['affiliated'],
	},
	{
		id: 'charts',
		title: 'sidemenu.carteiras',
		icon: <WalletAdd size={18} />,
		icon2: <WalletAdd variant="Bold" size={18} />,
		navLink: '/carteiras-cadastradas',
		roles: ['affiliated'],
	},
	{
		header: 'sidemenu.gamificao',
		roles: ['affiliated'],
	},
	{
		id: 'user-interface-icons',
		title: 'sidemenu.niveis',
		icon: <Rank size={18} />,
		icon2: <Rank variant="Bold" size={18} />,
		navLink: '/',
		roles: ['affiliated'],
	},

	{
		header: 'sidemenu.cadastros',
		roles: ['admin'],
	},
	{
		id: 'charts',
		title: 'menu.aprovarCadastro',
		icon: <LinkCircle size={18} />,
		icon2: <LinkCircle variant="Bold" size={18} />,
		navLink: '/cadastro',
		roles: ['admin'],
	},
	{
		header: 'sidemenu.saques',
		roles: ['admin'],
	},
	{
		id: 'charts',
		title: 'sidemenu.aprovarsaques',
		icon: <MoneyRecive size={18} />,
		icon2: <MoneyRecive variant="Bold" size={18} />,
		navLink: '/saques',
		roles: ['admin'],
	},
	{
		header: 'sidemenu.constestacoes',
		roles: ['admin'],
	},
	{
		id: 'charts',
		title: 'home.solicitacoes',
		icon: <Clock size={18} />,
		icon2: <Clock variant="Bold" size={18} />,
		navLink: '/contestation-admin',
		roles: ['admin'],
	},
];

export default navigation;
