import { Dropdown, Col, Divider, Button } from 'antd';
import { Link } from 'react-router-dom';
import { UserSquare, LogoutCurve, MessageQuestion } from 'iconsax-react';
import avatarImg from 'src/assets/images/memoji/memoji-3.png';
import useAuth from 'src/contexts/AuthContext';

import './header.css';

import { useTranslation } from 'react-i18next';

export default function HeaderUser() {
	const { logout } = useAuth();

	const { t } = useTranslation();

	const handleLogout = () => {
		logout();
	};

	const menu = (
		<div className="hp-user-dropdown dropdown_content hp-border-radius hp-border-color-dark-80 hp-pt-6 hp-px-18 hp-mt-16">
			<div className="dropdown_title">{t('profile.perfil')}</div>

			<Link to="/profile" className="dropdown_opt">
				<UserSquare size="20" color="#4E4E4E" />

				{t('profile.viwPerfil')}
			</Link>

			<Link
				onClick={() =>
					window.open(
						'https://api.whatsapp.com/send?phone=553195988104&text=Ol%C3%A1,%20quero%20me%20tornar%20um%20afiliado%20da%C2%A0Arbety',
						'_blank'
					)
				}
				to=""
				target="_blank"
				className="dropdown_opt"
			>
				<MessageQuestion size="20" color="#4E4E4E" />
				{t('profile.suporte')}
			</Link>

			<Divider className="hp-mb-4 hp-mt-0" />

			<Button
				onClick={(e) => {
					e.preventDefault();
					handleLogout();
				}}
				className="dropdown_opt"
			>
				<LogoutCurve size="20" color="#4E4E4E" />
				{t('profile.exit')}
			</Button>
		</div>
	);

	return (
		<Col>
			<Dropdown overlay={menu} placement="bottomLeft">
				<div className="hp-border-radius-xl hp-cursor-pointer hp-border-1 hp-border-color-dark-80">
					<div className="dropdown_avatar hp-border-radius-lg hp-overflow-hidden hp-bg-info-4 hp-m-4 hp-d-flex">
						<img src={avatarImg} alt="User" height="100%" />
					</div>
				</div>
			</Dropdown>
		</Col>
	);
}
