import './assets/icons/remixicon.css';
import './assets/less/yoda-theme.less';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Suspense } from 'react';
import App from './App';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { I18nextProvider } from 'react-i18next';
import i18n from './lib/i18n';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 30,
		},
	},
});

const root = createRoot(document.getElementById('root')!);
root.render(
	<I18nextProvider i18n={i18n}>
		<QueryClientProvider client={queryClient}>
			<Suspense fallback="loading">
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Suspense>
		</QueryClientProvider>
	</I18nextProvider>
);
