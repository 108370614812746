import { AdminProvider } from './contexts/AdminContext';
import { AuthProvider } from './contexts/AuthContext';
import { ConfigProvider } from 'antd';
import { DashProvider } from './contexts/DashContext';
import { DataProvider } from './contexts/DataContext';
import { IntlProvider } from 'react-intl';
import AppLocale from './languages';
import Router from './router/Router';
import { DadosProvider } from './contexts/DadosContext';
import ModalSaque from './pages/user-comissao-saque/components/ModalSaque';

export default function App() {
	const currentAppLocale = AppLocale.en;

	return (
		<AuthProvider>
			<AdminProvider>
				<DataProvider>
					<DashProvider>
						<DadosProvider>
							<ConfigProvider
								locale={currentAppLocale.antd}
								direction={'ltr'}
							>
								<IntlProvider
									locale={currentAppLocale.locale}
									messages={currentAppLocale.messages}
								>
									<Router />
									<ModalSaque />
								</IntlProvider>
							</ConfigProvider>
						</DadosProvider>
					</DashProvider>
				</DataProvider>
			</AdminProvider>
		</AuthProvider>
	);
}
