import antdEN from 'antd/es/locale-provider/en_US';
import enMessages from '../locales/en_US.json';

const enLang = {
	messages: {
		...enMessages,
	},
	antd: antdEN,
	locale: 'en-US',
};

export default enLang;
