import { Layout, Row, Col } from 'antd';
import { ReactNode, useState } from 'react';
import MenuFooter from './components/footer';
import MenuHeader from './components/header';
import ScrollTop from './components/scroll-to-top';
import Sidebar from './components/menu/Sidebar';
import { useLocation } from 'react-router-dom';

const { Content } = Layout;

export default function VerticalLayout(props: { children: ReactNode }) {
	const { children } = props;

	const [visible, setVisible] = useState(false);

	const location = useLocation();

	return (
		<Layout className="hp-app-layout">
			<Sidebar visible={visible} setVisible={setVisible} />

			<Layout
				className={` ${
					location.pathname === '/rank'
						? 'bg-[#222222]'
						: 'hp-bg-black-20 hp-bg-color-dark-90'
				} `}
			>
				<MenuHeader setVisible={setVisible} />

				<Content className="hp-content-main">
					<Row justify="center">
						<Col xxl={17} xl={22} span={24}>
							{children}
						</Col>
					</Row>
				</Content>

				<MenuFooter />
			</Layout>

			<ScrollTop />
		</Layout>
	);
}
