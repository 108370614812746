import { Divider, Avatar, Row, Col } from 'antd';
import { IntlMessages } from '../../lang/IntlMessages';
import { Link } from 'react-router-dom';
import avatar from '../../../../assets/images/memoji/memoji-3.png';
import useAuth from 'src/contexts/AuthContext';

export default function MenuFooter(props: {
	collapsed?: boolean;
	onClose?: () => void;
}) {
	const { userData } = useAuth();

	return props.collapsed === false ? (
		<Row
			className="hp-sidebar-footer hp-bg-color-dark-90"
			align="middle"
			justify="space-between"
		>
			<Divider className=" hp-border-color-dark-70 hp-mt-0" />

			<Col>
				<Row align="middle">
					<Avatar
						size={48}
						src={avatar}
						className="hp-bg-info-4 hp-mr-8"
					/>

					<div className="hp-mt-6">
						<span
							className="hp-d-block hp-text-color-black-100 hp-text-color-dark-0 hp-p1-body"
							style={{ lineHeight: 1 }}
						>
							{userData?.first_name}
						</span>

						<Link
							to="/profile"
							className="hp-badge-text hp-text-color-dark-30 hp-font-weight-400"
							onClick={props.onClose}
						>
							<IntlMessages id="sidebar-view-profile" />
						</Link>
					</div>
				</Row>
			</Col>
		</Row>
	) : (
		<Row
			className="hp-sidebar-footer hp-bg-color-dark-90"
			align="middle"
			justify="center"
		>
			<Col>
				<Link to="/profile" onClick={props.onClose}>
					<Avatar size={48} src={avatar} className="hp-bg-info-4" />
				</Link>
			</Col>
		</Row>
	);
}
