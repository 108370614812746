export default async function realizarConversao(
	valorEmBRL: number
): Promise<string> {
	const apiKey: string = 'babb825dfe6e493fb3f5e0d70762f096';
	const baseCurrency: string = 'USD';
	const targetCurrency: string = 'BRL';

	const apiUrl: string = `https://open.er-api.com/v6/latest/${baseCurrency}?apikey=${apiKey}`;

	try {
		const response = await fetch(apiUrl, { mode: 'cors' });
		const data = await response.json();

		if (data && data.rates && data.rates[targetCurrency]) {
			const taxaDeCambio: number = data.rates[targetCurrency];
			const valorEmUSD: number = valorEmBRL / taxaDeCambio;
			return valorEmUSD.toFixed(2);
		} else {
			throw new Error('Erro ao obter as taxas de câmbio.');
		}
	} catch (error) {
		throw new Error(`Erro ao realizar a conversão`);
	}
}

export async function realizarConver2(valorEmBRL: number): Promise<string> {
	const apiKey: string = 'babb825dfe6e493fb3f5e0d70762f096';
	const baseCurrency: string = 'BRL';
	const targetCurrency: string = 'USD';

	const apiUrl: string = `https://open.er-api.com/v6/latest/${baseCurrency}?apikey=${apiKey}`;

	try {
		const response = await fetch(apiUrl, { mode: 'cors' });
		const data = await response.json();

		if (data && data.rates && data.rates[targetCurrency]) {
			const taxaDeCambio: number = data.rates[targetCurrency];
			const valorEmUSD: number = valorEmBRL / taxaDeCambio;
			return valorEmUSD.toFixed(2);
		} else {
			throw new Error('Erro ao obter as taxas de câmbio.');
		}
	} catch (error) {
		throw new Error(`Erro ao realizar a conversão`);
	}
}
