import { ArrowDown2, ArrowUp2 } from 'iconsax-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import brFlag from './icons/br.svg';
import usFlag from './icons/us.svg';
import esFlag from './icons/es.svg';

import arFlag from './icons/ar.svg';
import clFlag from './icons/cl.svg';
import crFlag from './icons/cr.svg';
import mxFlag from './icons/mx.svg';
import peFlag from './icons/pe.svg';
import useDados from 'src/contexts/DadosContext';
import useAuth from 'src/contexts/AuthContext';

export const LocaleSelector = () => {
	const { i18n } = useTranslation();
	const [options, setOptions] = useState(true);
	const [selectedOption, setSelectedOption] = useState('pt-BR');
	const [moeda, setMoeda] = useState('BRL');

	const { setPtBR } = useDados();

	const handleOptionClick = (newOption: string) => {
		setSelectedOption(newOption);
		setOptions(!options);
	};

	const changeLanguage = (lng: any) => {
		i18n.changeLanguage(lng);
	};

	const { userData } = useAuth();

	const usd = userData?.usd;

	const getFlagImage = (flagCode: string) => {
		switch (flagCode) {
			case 'en-US':
				return usFlag;
			case 'es':
				return esFlag;
			case 'pt-BR':
			default:
				return brFlag;
		}
	};

	useEffect(() => {
		if (usd === null || usd === 0) {
			setSelectedOption('pt-BR');
			setMoeda('BRL');
			i18n.changeLanguage('pt-BR');
			setPtBR(false);
		} else {
			setSelectedOption('en-US');
			setMoeda('USD');
			i18n.changeLanguage('en-US');
			setPtBR(true);
		}
	}, [usd]);

	return (
		<div className="bg-[#222734] cursor-pointer border-solid border-[#0afe68] border-[2px] rounded-[10px] relative z-50">
			<button
				className="px-[0.30rem] h-[40px] rounded-md flex items-center gap-2 justify-center text-white font-semibold"
				onClick={() => {
					setOptions(!options);
				}}
			>
				<img
					className="h-[30px] w-[30px] rounded-[100px]"
					src={getFlagImage(selectedOption)}
					alt=""
				/>
				{moeda}
				{options ? (
					<ArrowDown2
						className="z-50"
						size="20"
						color="#fff"
						variant="Bold"
					/>
				) : (
					<ArrowUp2
						className="z-50"
						size="20"
						color="#fff"
						variant="Bold"
					/>
				)}
			</button>
			{!options && (
				<div className="w-full bg-[#222734] flex flex-col absolute z-50 rounded-md">
					<button
						onClick={() => {
							handleOptionClick('en-US');
							changeLanguage('en-US');
							setMoeda('USD');
							setPtBR(true);
						}}
						className="w-full h-[40px] justify-center items-center hover:bg-[#2e333f]"
					>
						<div className="max-h-[40px] px-[0.30rem] rounded-md flex items-center gap-2 justify-center text-white font-semibold">
							<img
								className="h-[30px] w-[30px] rounded-[100px]"
								src={usFlag}
								alt=""
							/>
							- USD
						</div>
					</button>

					<button
						onClick={() => {
							handleOptionClick('es');
							changeLanguage('es');
							setMoeda('USD');
							setPtBR(true);
						}}
						className="w-full h-[40px] justify-center items-center transition-all hover:bg-[#2e333f]"
					>
						<div className="max-h-[40px] px-[0.30rem] rounded-md flex items-center gap-2 justify-center text-white font-semibold">
							<img
								className="h-[30px] w-[30px] rounded-[100px]"
								src={esFlag}
								alt=""
							/>
							- USD
						</div>
					</button>
					<button
						onClick={() => {
							handleOptionClick('pt-BR');
							changeLanguage('pt-BR');
							setMoeda('BRL');
							setPtBR(false);
						}}
						className="w-full  h-[40px] justify-center items-center transition-all hover:bg-[#2e333f] rounded-b-md"
					>
						<div className="max-h-[40px] px-[0.30rem] rounded-md flex items-center gap-2 justify-center text-white font-semibold">
							<img
								className="h-[30px] w-[30px] rounded-[100px]"
								src={brFlag}
								alt=""
							/>
							- BRL
						</div>
					</button>
				</div>
			)}
		</div>
	);
};

export const LocaleSelectorName = (props: any) => {
	const { setCountry, setCurrency } = props;
	const { i18n } = useTranslation();

	const [options, setOptions] = useState(true);
	const [selectedOption, setSelectedOption] = useState('br');
	const [name, setName] = useState('Brazil - BRL');

	const handleOptionClick = (newOption: string) => {
		setSelectedOption(newOption);
		setOptions(!options);
	};

	const getFlagImage = (flagCode: string) => {
		switch (flagCode) {
			case 'ar':
				return arFlag;
			case 'cl':
				return clFlag;
			case 'cr':
				return crFlag;
			case 'mx':
				return mxFlag;
			case 'pe':
				return peFlag;
			case 'br':
			default:
				return brFlag;
		}
	};

	return (
		<div className="w-full bg-[#222734] cursor-pointer border-solid border-[#0afe68] border-[2px] rounded-[10px] relative z-50">
			<div
				className={` p-[0.30rem] flex items-center gap-2 font-bold text-white ${
					options ? 'rounded-md ' : 'rounded-t-md '
				}`}
				onClick={() => {
					setOptions(!options);
				}}
			>
				<img
					className="h-[30px] w-[30px] rounded-[100px]"
					src={getFlagImage(selectedOption)}
					alt=""
				/>
				{name}
				{options ? (
					<ArrowDown2 size="20" color="#fff" variant="Bold" />
				) : (
					<ArrowUp2 size="20" color="#fff" variant="Bold" />
				)}
			</div>

			{!options && (
				<div className="w-full bg-[#222734] flex flex-col absolute z-50">
					<div
						onClick={() => {
							handleOptionClick('br');
							setName('Brazil - BRL');
							setCountry('BR');
							setCurrency('BRL');
							i18n.changeLanguage('pt-BR');
						}}
						className="w-full bg-[#222734] hover:bg-[#2e333f]"
					>
						<div className="p-[0.30rem] rounded-md flex items-center gap-2 justify-start text-white font-semibold">
							<img
								className="h-[30px] w-[30px] rounded-[100px]"
								src={brFlag}
								alt=""
							/>
							Brazil - BRL
						</div>
					</div>

					<div
						onClick={() => {
							handleOptionClick('ar');
							setName('Argentina - ARS');
							setCountry('AR');
							setCurrency('ARS');
							i18n.changeLanguage('es');
						}}
						className="w-full bg-[#222734] transition-all hover:bg-[#2e333f]"
					>
						<div className="p-[0.30rem] rounded-md flex items-center gap-2 justify-start text-white font-semibold">
							<img
								className="h-[30px] w-[30px] rounded-[100px]"
								src={arFlag}
								alt=""
							/>
							Argentina - ARS
						</div>
					</div>

					<div
						onClick={() => {
							handleOptionClick('cl');
							setName('Chile - CLP');
							setCountry('CL');
							setCurrency('CLP');
							i18n.changeLanguage('es');
						}}
						className="w-full bg-[#222734] transition-all hover:bg-[#2e333f]"
					>
						<div className="p-[0.30rem] rounded-md flex items-center gap-2 justify-start text-white font-semibold">
							<img
								className="h-[30px] w-[30px] rounded-[100px]"
								src={clFlag}
								alt=""
							/>
							Chile - CLP
						</div>
					</div>
					<div
						onClick={() => {
							handleOptionClick('mx');
							setName('Mexico - MXN');
							setCountry('MX');
							setCurrency('MXN');
							i18n.changeLanguage('es');
						}}
						className="w-full bg-[#222734] transition-all hover:bg-[#2e333f]"
					>
						<div className="p-[0.30rem] rounded-md flex items-center gap-2 justify-start text-white font-semibold">
							<img
								className="h-[30px] w-[30px] rounded-[100px]"
								src={mxFlag}
								alt=""
							/>
							Mexico - MXN
						</div>
					</div>
					<div
						onClick={() => {
							handleOptionClick('cr');
							setName('Costa Rica - CRC');
							setCountry('CR');
							setCurrency('CRC');
							i18n.changeLanguage('es');
						}}
						className="w-full bg-[#222734] transition-all hover:bg-[#2e333f]"
					>
						<div className="p-[0.30rem] rounded-md flex items-center gap-2 justify-start text-white font-semibold">
							<img
								className="h-[30px] w-[30px] rounded-[100px]"
								src={crFlag}
								alt=""
							/>
							Costa Rica - CRC
						</div>
					</div>
					<div
						onClick={() => {
							handleOptionClick('pe');
							setName('Peru - PEN');
							setCountry('PE');
							setCurrency('PEN');
							i18n.changeLanguage('es');
						}}
						className="w-full bg-[#222734] transition-all hover:bg-[#2e333f]"
					>
						<div className="p-[0.30rem] rounded-md flex items-center gap-2 justify-start text-white font-semibold">
							<img
								className="h-[30px] w-[30px] rounded-[100px]"
								src={peFlag}
								alt=""
							/>
							Peru - PEN
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
