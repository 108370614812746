import { arbetyApiUrl } from './constants';
// import { validateDates } from "./functions";
import { ReactNode, createContext, useContext } from 'react';
import axios, { AxiosResponse } from 'axios';
import useAuth from './AuthContext';

type DashContextProps = {
	getPartnerRevenueShare: (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => Promise<
		AxiosResponse<{
			code: number;
			revenue_share: number;
			partnerId: string;
			revenue_share_pending: number;
			revenue_share_total: number;
			revenue_paid: number;
		}>
	>;
	getPartnerCPA: (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => Promise<
		AxiosResponse<{
			code: number;
			cpa: number;
			cpa_amount: number;
			partnerId: string;
			cpa_pending_amount: number;
			cpa_pending: number;
			cpa_paid_amount: number;
			cpa_paid: number;
			cpa_total: number;
		}>
	>;
	getRegisters: (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string,
		page?: number
	) => Promise<
		AxiosResponse<{
			total: number;
			users: any;
		}>
	>;
	getPartnerBalance: (
		initial_date?: string,
		end_date?: string
	) => Promise<
		AxiosResponse<{
			code: number;
			balance: number;
		}>
	>;
	getPartnerWithdraws: (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => Promise<
		AxiosResponse<{
			code: number;
			quantity: number;
			amount: number;
		}>
	>;
	getPartnerWithdraw: any;
	getPartnerPayments: (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => Promise<
		AxiosResponse<{
			code: number;
			cpa: Object[];
			cpa_total: number;
			message: string;
			revenue_share: Object[];
			revenue_share_total: number;
		}>
	>;
	getPartnerDeposits: (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => Promise<
		AxiosResponse<{
			code: number;
			quantity: number;
			amount: number;
		}>
	>;
	getPartnerFirstDeposits: (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => Promise<
		AxiosResponse<{
			code: number;
			quantity: number;
			amount: number;
		}>
	>;
	getPartnerLinks: () => Promise<
		AxiosResponse<{
			code: number;
			message: string;
			links: {
				id: string;
				name: string;
				registers: number;
				userId: string;
				created_at: string;
				min_deposit: string;
			}[];
		}>
	>;
	deletePartnerLink: (linkId: string) => Promise<AxiosResponse>;
	getPartnerRegisters: () => Promise<
		AxiosResponse<{
			code: number;
			last_registers: {
				id: string;
				balance: number;
				bonusBalance: number;
				partner_link: string;
				revenue_share: number;
				cpa: number;
				deposits: number;
				deposits_quantity: number;
				withdraws: number;
			}[];
		}>
	>;
	getPartnerRedeposits: (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => Promise<
		AxiosResponse<{
			code: number;
			quantity: number;
			amount: number;
		}>
	>;
	getPartnerSubPartners: (
		initial_date?: string,
		end_date?: string
	) => Promise<
		AxiosResponse<{
			code: number;
			revenue_share: number;
			cpa: number;
			subpartners: {
				id: string;
				email: string;
				cpa: number;
				revenue_share: number;
			}[];
		}>
	>;

	getRevenueShareCustom: (
		initial_date?: string,
		end_date?: string
	) => Promise<
		AxiosResponse<{
			amount: number;
		}>
	>;

	getCpaCustom: (
		initial_date?: string,
		end_date?: string
	) => Promise<
		AxiosResponse<{
			cpa: number;
			registers: number;
		}>
	>;

	getFtdCustom: (
		initial_date?: string,
		end_date?: string
	) => Promise<
		AxiosResponse<{
			quantity: number;
		}>
	>;
};

const DashContext = createContext({} as DashContextProps);

const afterDate = new Date();
afterDate.setMonth(afterDate.getMonth(), 1);
const beforeDate = new Date();
beforeDate.setMonth(beforeDate.getMonth() + 1, 0);

const dateRange = {
	initial_date: afterDate.toISOString().split('T')[0],
	end_date: beforeDate.toISOString().split('T')[0],
};

export const DashProvider = ({ children }: { children: ReactNode }) => {
	const { accessToken } = useAuth();

	const getRevenueShareCustom = async (
		initial_date?: string,
		end_date?: string
	) => {
		return axios.post(
			`${arbetyApiUrl}/partners/deposits/custom`,
			{
				initial_date: initial_date,
				end_date: end_date,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const getCpaCustom = async (initial_date?: string, end_date?: string) => {
		return axios.post(
			`${arbetyApiUrl}/partners/cpa/custom`,
			{
				initial_date: initial_date,
				end_date: end_date,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const getFtdCustom = async (initial_date?: string, end_date?: string) => {
		return axios.post(
			`${arbetyApiUrl}/partners/ftd/custom`,
			{
				initial_date: initial_date,
				end_date: end_date,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const getPartnerRevenueShare = async (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => {
		return axios.post(
			`${arbetyApiUrl}/partners/revenueshare`,
			{
				...(initial_date && end_date
					? { initial_date, end_date }
					: dateRange),
				...(typeof byLink === 'boolean' && byLink && linkName
					? {
							by_link: byLink,
							link: linkName,
					  }
					: {
							by_link: false,
					  }),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const getPartnerCPA = async (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => {
		return axios.post(
			`${arbetyApiUrl}/partners/cpa`,
			{
				...(initial_date && end_date
					? { initial_date, end_date }
					: dateRange),
				...(typeof byLink === 'boolean' && byLink && linkName
					? {
							by_link: byLink,
							link: linkName,
					  }
					: {
							by_link: false,
					  }),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const getPartnerBalance = async (
		initial_date?: string,
		end_date?: string
	) => {
		return axios.post(
			`${arbetyApiUrl}/partners/balance`,
			{
				...(initial_date && end_date
					? { initial_date, end_date }
					: dateRange),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const getPartnerWithdraws = async (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => {
		return axios.post(
			`${arbetyApiUrl}/partners/withdraws`,
			{
				...(initial_date && end_date
					? { initial_date, end_date }
					: dateRange),
				...(typeof byLink === 'boolean' && byLink && linkName
					? {
							by_link: byLink,
							link: linkName,
					  }
					: {
							by_link: false,
					  }),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const getPartnerWithdraw = async () => {
		return axios.get(`${arbetyApiUrl}/partners/withdraw`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: accessToken,
			},
		});
	};

	const getPartnerPayments = async (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => {
		return axios.post(
			`${arbetyApiUrl}/partners/payments`,
			{
				...(initial_date && end_date
					? { initial_date, end_date }
					: dateRange),
				...(typeof byLink === 'boolean' && byLink && linkName
					? {
							by_link: byLink,
							link: linkName,
					  }
					: {
							by_link: false,
					  }),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const getPartnerDeposits = async (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => {
		return axios.post(
			`${arbetyApiUrl}/partners/deposits`,
			{
				...(initial_date && end_date
					? { initial_date, end_date }
					: dateRange),
				...(typeof byLink === 'boolean' && byLink && linkName
					? {
							by_link: byLink,
							link: linkName,
					  }
					: {
							by_link: false,
					  }),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const getPartnerFirstDeposits = async (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => {
		return axios.post(
			`${arbetyApiUrl}/partners/first-deposits`,
			{
				...(initial_date && end_date
					? { initial_date, end_date }
					: dateRange),
				...(typeof byLink === 'boolean' && byLink && linkName
					? {
							by_link: byLink,
							link: linkName,
					  }
					: {
							by_link: false,
					  }),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const getPartnerLinks = async () => {
		return axios.get(`${arbetyApiUrl}/partners/links`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: accessToken,
			},
		});
	};

	const deletePartnerLink = async (linkId: string) => {
		return axios.delete(`${arbetyApiUrl}/partners/links`, {
			data: {
				id: linkId,
			},
			headers: {
				'Content-Type': 'application/json',
				Authorization: accessToken,
			},
		});
	};

	const getPartnerRegisters = async () => {
		return axios.get(`${arbetyApiUrl}/partners/registers`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: accessToken,
			},
		});
	};

	const getPartnerRedeposits = async (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string
	) => {
		return axios.post(
			`${arbetyApiUrl}/partners/re-deposits`,
			{
				...(initial_date && end_date
					? { initial_date, end_date }
					: dateRange),
				...(typeof byLink === 'boolean' && byLink && linkName
					? {
							by_link: byLink,
							link: linkName,
					  }
					: {
							by_link: false,
					  }),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const getPartnerSubPartners = async (
		initial_date?: string,
		end_date?: string
	) => {
		return axios.post(
			`${arbetyApiUrl}/partners/subpartners`,
			{
				...(initial_date && end_date
					? { initial_date, end_date }
					: dateRange),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const getRegisters = async (
		initial_date?: string,
		end_date?: string,
		byLink?: boolean,
		linkName?: string,
		page?: number
	) => {
		return axios.post(
			`${arbetyApiUrl}/partners/registers`,
			{
				...(initial_date && end_date
					? { initial_date, end_date }
					: dateRange),
				...(typeof byLink === 'boolean' && byLink && linkName
					? {
							by_link: byLink,
							link: linkName,
							page: page,
					  }
					: {
							by_link: false,
							page: 0,
					  }),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: accessToken,
				},
			}
		);
	};

	return (
		<DashContext.Provider
			value={{
				getPartnerRevenueShare,
				getPartnerCPA,
				getPartnerBalance,
				getPartnerDeposits,
				getPartnerLinks,
				deletePartnerLink,
				getPartnerFirstDeposits,
				getPartnerRegisters,
				getPartnerRedeposits,
				getPartnerWithdraws,
				getPartnerWithdraw,
				getPartnerSubPartners,
				getPartnerPayments,
				getRegisters,
				getRevenueShareCustom,
				getCpaCustom,
				getFtdCustom,
			}}
		>
			{children}
		</DashContext.Provider>
	);
};

const useDash = () => {
	const context = useContext(DashContext);
	if (!context) throw new Error('Provider inserido de maneira errada');
	return context;
};

export default useDash;
