export const formatCurrency = (number: number, currency = 'BRL') => {
	return number.toLocaleString('pt-BR', {
		style: 'currency',
		currency,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const formatCurrency2 = (number: number, currency = 'USD') => {
	return number.toLocaleString('es-AR', {
		style: 'currency',
		currency: currency,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};
