import antdTR from 'antd/es/locale-provider/tr_TR';
import saMessages from '../locales/tr_TR.json';

const trLang = {
	messages: {
		...saMessages,
	},
	antd: antdTR,
	locale: 'tr-TR',
};

export default trLang;
