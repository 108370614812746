// import MenuFooter from "./footer";
import { Layout, Row, Col } from 'antd';
import { motion } from 'framer-motion';
import { useState } from 'react';

import MenuItem from './item';
import MenuLogo from './logo';
import MenuMobile from './mobile';

import { useLocation } from 'react-router-dom';

const { Sider } = Layout;

export default function Sidebar(props: {
	visible: boolean;
	setVisible: (visible: boolean) => void;
}) {
	const { visible, setVisible } = props;

	const [collapsed] = useState(false);

	// Mobile Sidebar
	const onClose = () => {
		setVisible(false);
	};

	const location = useLocation();

	return (
		<Sider
			trigger={null}
			collapsible
			collapsed={collapsed}
			width={316}
			className={` ${
				location.pathname === '/rank'
					? 'bg-[#222222]'
					: 'hp-bg-black-20 hp-bg-color-dark-90'
			} hp-sidebar hp-border-color-dark-80 `}
		>
			<motion.div
				initial={{ opacity: 0, x: -50 }}
				animate={{ opacity: 1, x: 0 }}
				transition={{ type: 'spring', duration: 0.5, delay: 0.1 }}
				className="hp-d-flex hp-h-100"
				style={{ flexDirection: 'column' }}
			>
				<Row align="bottom" justify="space-between">
					<Col>
						{collapsed === false ? (
							<MenuLogo onClose={onClose} />
						) : (
							''
						)}
					</Col>

					{collapsed && (
						<Col
							span={24}
							className="hp-mt-12 hp-d-flex-full-center"
						>
							<MenuLogo onClose={onClose} />
						</Col>
					)}
				</Row>

				<MenuItem onClose={onClose} />

				<MenuMobile onClose={onClose} visible={visible} />
			</motion.div>
		</Sider>
	);
}
