import { Col, Row } from 'antd';

import { useTranslation } from 'react-i18next';

export default function Footer() {
	const { t } = useTranslation();

	return (
		<Col span={24} className="hp-py-32">
			<p className="hp-mb-8 hp-p1-body hp-text-color-black-60">
				{t('termos.copy')}
			</p>

			<Row align="middle" justify="center" gutter={16}>
				<Col>
					<span className="hp-p1-body hp-text-color-black-80 hp-hover-text-color-primary-1">
						Privacy Policy
					</span>
				</Col>

				<Col>
					<span className="hp-p1-body hp-text-color-black-80 hp-hover-text-color-primary-1">
						Term of use
					</span>
				</Col>
			</Row>
		</Col>
	);
}
