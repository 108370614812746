import { Lock1 } from 'iconsax-react';
import { Menu } from 'antd';
import { useLocation, Link } from 'react-router-dom';
// import navigation from '../../../../navigation';
import useAuth from 'src/contexts/AuthContext';

import { useTranslation } from 'react-i18next';
import navigation from 'src/navigation';

export default function MenuItem(props: { onClose: () => void }) {
	const { onClose } = props;

	const { verifyPass, initialized, userData } = useAuth();

	const { pathname } = useLocation();

	const { t } = useTranslation();

	const splitLocation = pathname.split('/');

	const menuItem = navigation.map((item, index) => {
		if (!initialized || !userData) return null;
		const { canPass } = verifyPass(item.roles);

		if (!canPass) return null;

		if (item.header) {
			return (
				<Menu.ItemGroup
					key={index}
					title={item.header && t(item.header)}
				></Menu.ItemGroup>
			);
		}

		const itemNavLink = item.navLink ? item.navLink.split('/') : '';

		const isNavLinkSelected =
			splitLocation[splitLocation.length - 2] +
				'/' +
				splitLocation[splitLocation.length - 1] ===
			itemNavLink[itemNavLink.length - 2] +
				'/' +
				itemNavLink[itemNavLink.length - 1];

		return (
			<Menu.Item
				key={item.id}
				onClick={onClose}
				className={
					isNavLinkSelected
						? 'ant-menu-item-selected'
						: location.pathname === '/rank'
						? 'outra-classe-para-rank'
						: 'ant-menu-item-selected-in-active'
				}
			>
				{(item.navLink && item.navLink.length === 0) ||
				!item.navLink ? (
					<div className="flex justify-between items-center opacity-50">
						<Link
							className="flex items-center gap-5"
							to="/analises"
						>
							{isNavLinkSelected ? item.icon2 : item.icon}

							{item.title && t(item.title)}
						</Link>
						<Lock1
							className="mr-[16px]"
							size="14"
							variant="Bold"
							color="#4E4E4E"
						/>
					</div>
				) : (
					<Link className="flex items-center gap-5" to={item.navLink}>
						{isNavLinkSelected ? item.icon2 : item.icon}

						{item.title && t(item.title)}
					</Link>
				)}
			</Menu.Item>
		);
	});

	return (
		<Menu
			mode="inline"
			defaultOpenKeys={[
				splitLocation.length === 5
					? splitLocation[splitLocation.length - 3]
					: '',
				splitLocation[splitLocation.length - 2],
			]}
			theme={'light'}
			className={` ${
				location.pathname === '/rank'
					? 'bg-[#222222]'
					: 'hp-bg-black-20 hp-bg-color-dark-90'
			} `}
		>
			{menuItem}
		</Menu>
	);
}
