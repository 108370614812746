import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'src/contexts/AuthContext';

export default function MenuFooter() {
	const { Footer } = Layout;

	const { t } = useTranslation();

	const { userData } = useAuth();

	const location = useLocation();

	const navigate = useNavigate();

	const admin = userData?.is_admin;

	return admin === 1 ? (
		<Footer
			className={`${
				location.pathname === '/rank'
					? 'bg-[#222222]'
					: 'hp-bg-color-black-20 hp-bg-color-dark-90'
			}`}
		>
			<div className="w-full flex justify-between">
				<p className="hp-badge-text text-[15px] cursor-pointer hp-font-weight-600 hp-mb-0 hp-text-color-dark-30">
					{t('termos.copy')}
				</p>

				<p
					onClick={() => navigate('/termo')}
					className="hp-badge-text text-[15px] cursor-pointer hp-font-weight-600 hp-mb-0 hp-text-color-dark-30"
				>
					{t('termos.termosCondicoes')}
				</p>
			</div>
		</Footer>
	) : (
		<Footer
			className={`${
				location.pathname === '/rank'
					? 'bg-[#222222]'
					: 'hp-bg-color-black-20 hp-bg-color-dark-90'
			}`}
		>
			<div className="w-full flex justify-between">
				<p className="hp-badge-text text-[15px] cursor-pointer hp-font-weight-600 hp-mb-0 hp-text-color-dark-30">
					{t('termos.copy')}
				</p>

				<p
					onClick={() => navigate('/termos')}
					className="hp-badge-text text-[15px] cursor-pointer hp-font-weight-600 hp-mb-0 hp-text-color-dark-30"
				>
					{t('termos.termosCondicoes')}
				</p>
			</div>
		</Footer>
	);
}
