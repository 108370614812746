import { Navigate, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import { PagesRoutes } from './routes';
import { Suspense, useEffect } from 'react';
import Error404 from 'src/pages/errors/404';
import RoleGuard from './guard/RoleGuard';
import VerticalLayout from 'src/layout/VerticalLayout';
import FullLayout from 'src/layout/FullLayout';

export default function Router() {
	useEffect(() => {
		document.querySelector('body')!.classList.add('light');
	}, []);

	return (
		<Routes>
			{PagesRoutes.map((route) => {
				const RouteLayout =
					route.layout === 'FullLayout' ? FullLayout : VerticalLayout;
				return (
					<Route
						key={route.path}
						path={route.path}
						element={
							<RouteLayout>
								<RoleGuard roles={route.roles}>
									<Suspense fallback={null}>
										{route.layout === 'FullLayout' ? (
											<route.component />
										) : (
											<motion.div
												initial={{
													opacity: 0,
													y: 50,
												}}
												animate={{
													opacity: 1,
													y: 0,
												}}
												transition={{
													type: 'spring',
													duration: 0.5,
													delay: 0.5,
												}}
											>
												<route.component />
											</motion.div>
										)}
									</Suspense>
								</RoleGuard>
							</RouteLayout>
						}
					/>
				);
			})}

			<Route path={'/'} element={<Navigate to="/login" />} />

			<Route path="*" element={<Error404 />} />
		</Routes>
	);
}
