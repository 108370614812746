export default function Line() {
	return (
		<div className="hp-position-absolute-top-left hp-w-100 hp-h-100">
			<svg
				className="hp-stroke-dark-90"
				width="100%"
				height="100%"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M3574 1540L907.175 -134.641L1053.03 429.011L387.731 -53.7583L937.881 929.474L32.0529 297.577L254.672 889.032L-2135.09 -460"
					stroke="#F7FAFC"
					strokeWidth="100"
					strokeLinejoin="bevel"
				/>
			</svg>
		</div>
	);
}
